const gethexToRGBAId = (hex: string, alpha: any) => {
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values from hex
  const r = parseInt(hex?.substring(0, 2), 16);
  const g = parseInt(hex?.substring(2, 4), 16);
  const b = parseInt(hex?.substring(4, 6), 16);
  // Return the rgba string with the provided alpha
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default gethexToRGBAId;

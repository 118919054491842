import { DateTime } from 'luxon';
import getTimeZone from './getTimeZone';

function getTimeZoneSpecificDate(dateUTC, timezone = 'Europe/Amsterdam', returnTime = true) {
  if (typeof dateUTC === 'number') {
    dateUTC = DateTime.fromMillis(dateUTC).toISO();
  } else {
    dateUTC = dateUTC?.indexOf('T') === -1 ? dateUTC?.substring(0, 19)?.replace(' ', 'T')?.concat('.000Z') : dateUTC;
  }
  const timeZone = getTimeZone() || timezone;
  return dateUTC ? DateTime.fromISO(dateUTC, { zone: 'utc' })
    .setZone(timeZone)
    .toFormat(returnTime ? 'dd LLL, yyyy HH:mm:ss' : 'dd LLL, yyyy') : 'N/A';
}

export default getTimeZoneSpecificDate;

import { isNull, isUndefined } from 'lodash';

function getTimeZone() {
  const userLocal: any = JSON.parse(window.localStorage.getItem('user'));

  let timeZone = process.env.REACT_APP_PLATFORM_DEFAULT_TIMEZONE;

  if (!isNull(userLocal) && !isUndefined(userLocal)) {
    if (!isNull(userLocal?.timezone) && !isUndefined(userLocal?.timezone)) {
      timeZone = userLocal?.timezone;
      return timeZone;
    }
  }

  const partnerGlobalConfigLocal: any = JSON.parse(window.localStorage.getItem('partner_settings'));
  if (!isNull(partnerGlobalConfigLocal) && !isUndefined(partnerGlobalConfigLocal)) {
    if (!isNull(partnerGlobalConfigLocal?.timezone) && !isUndefined(partnerGlobalConfigLocal?.timezone)) {
      timeZone = partnerGlobalConfigLocal?.timezone;
    }
  }
  return timeZone;
}

export default getTimeZone;

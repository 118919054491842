import i18n from 'i18next';
import { isNull, isUndefined } from 'lodash';
import { initReactI18next } from 'react-i18next';
import { de } from './locale/de';
import { dk } from './locale/dk';
import { en } from './locale/en';
import { fr } from './locale/fr';
import { nl } from './locale/nl';

const resources = {
  en: {
    en
  },
  nl: {
    nl
  },
  de: {
    de
  },
  dk: {
    dk
  },
  fr: {
    fr
  }
};

const hostName = window.location.hostname;
let defaultLanguage = '';
if (!isUndefined(process.env.REACT_APP_DEFAULT_LOCALE) && !isNull(process.env.REACT_APP_DEFAULT_LOCALE)) {
  defaultLanguage = process.env.REACT_APP_DEFAULT_LOCALE;
} else if (hostName?.indexOf('baxi') !== -1) {
  defaultLanguage = 'en';
} else if (hostName?.indexOf('fortes.it22.cloud') !== -1) {
  defaultLanguage = 'en';
} else {
  defaultLanguage = 'nl';
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('locale') || defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false
    }
  });

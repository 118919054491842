import axios from 'axios';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';
import { KeycloakLogin } from '../types/keycloak_login';
import { User } from '../types/user';

class AuthApi {
  async login({ email, password }): Promise<KeycloakLogin> {
    return new Promise((resolve, reject) => {
      try {
        // Find the user
        axios.post(`${process.env.REACT_APP_USERS_URL}/login`, {
          username: email,
          password,
        })
          .then((response) => {
            response = parse(response);
            if (!isUndefined(response?.data)) {
              const data = response?.data;
              resolve(data);
            } else {
              reject(new Error(i18next.t('login_credentials_error')));
              reject(new Error(i18next.t('Please check your email and password')));
            }
          })
          .catch((error) => {
            logger('error', error);
            reject(new Error(i18next.t('Please check your email and password')));
          });
      } catch (err) {
        logger('[Auth Api]:', err);
        reject(new Error(i18next.t('Please check your email and password')));
      }
    });
  }

  async register(): Promise<void> {
    console.log('register');
  }

  me(accessToken, permissions, keyCloakData: any = {}): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const decoded: any = jwtDecode(accessToken);
        if (!decoded) {
          reject(new Error('Invalid authorization token'));
          return;
        }

        const obj: any = {
          user_id: decoded?.sub,
          name: decoded.name,
          email: decoded.email,
          permissions,
          is_2fa_enabled: keyCloakData?.is_2fa_enabled,
          isCookieConsent: keyCloakData?.isCookieConsent,
          isPrivacyPolicyDocReviewed: keyCloakData?.isPrivacyPolicyDocReviewed,
          isTermsAndConditionsDocReviewed: keyCloakData?.isTermsAndConditionsDocReviewed,
        };

        resolve(obj);
      } catch (err) {
        reject(new Error('Invalid authorization token'));
      }
    });
  }

  async refreshSession(refreshToken): Promise<KeycloakLogin> {
    try {
      // Make the request to refresh the session
      const response = await axios.post(`${process.env.REACT_APP_USERS_URL}/refresh_token`, {
        refresh_token: refreshToken,
      });

      // Parse and validate the response
      const parsedResponse = parse(response);
      const responseData = parsedResponse?.data;

      if (!responseData) {
        throw new Error('Invalid response: Missing data.');
      }

      const tokens = responseData?.data || responseData;
      if (!tokens?.access_token || !tokens?.refresh_token) {
        throw new Error('Invalid response: Missing access or refresh tokens.');
      }

      // Store tokens in localStorage
      localStorage.setItem('accessToken', tokens.access_token);
      localStorage.setItem('refreshToken', tokens.refresh_token);

      return tokens;
    } catch (error: any) {
      // Log the error and rethrow with meaningful messages
      logger(error, 'error');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      throw new Error(error.response?.data?.message || 'Failed to refresh session. Token might be expired.');
    }
  }
}

export const authApi = new AuthApi();
